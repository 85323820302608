import React, { useState } from "react"
import styles from "./styles.module.scss"
import { gql, useQuery, useMutation } from "@apollo/client"
import { DONATION_TYPES } from "../../constants/donation-types"
import { GET_ORGS_FOR_CAROUSEL } from "../../queries/getDataForCarousel"
import { Select, Input, Form, Button, InputNumber, Alert, Checkbox } from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import Loading from "../Loading"
import ButtonCustom from "../ButtonCustom"
import { resizeFamily } from "../../api"

const INSERT_STORY = gql`
  mutation insertStory(
    $display_name: String!
    $internal_name: String!
    $location_text: String!
    $members: String!
    $organization_id: uuid!
    $situation: String!
    $size: Int!
    $needs: [needs_insert_input!]!
  ) {
    insert_families(
      objects: {
        display_name: $display_name
        internal_name: $internal_name
        location_text: $location_text
        members: $members
        organization_id: $organization_id
        situation: $situation
        size: $size
        needs: { data: $needs }
      }
    ) {
      returning {
        family_id
      }
    }
  }
`

const GET_FAMILY = gql`
  query getSpecificFamily($family_id: uuid!) {
    families(where: { family_id: { _eq: $family_id } }) {
      display_name
      internal_name
      location_text
      members
      organization_id
      situation
      size
      needs {
        need_type
        name
        value
      }
    }
  }
`

const StoryBuilder = ({ editingFamilyId }) => {
  const { data: organizations, loading: orgsLoading } = useQuery(
    GET_ORGS_FOR_CAROUSEL
  )
  // const [getExistingStory, { data: currentStory, loading: currentStoryLoading}] = useLazyQuery(GET_FAMILY, { variables: { family_id: editingFamilyId } })

  const [addFamily] = useMutation(INSERT_STORY)
  const [status, setStatus] = useState({ type: null, message: null })
  const [resizeOnSave, setResizeOnSave] = useState(true)
  const [createdFamily, setCreatedFamily] = useState(null)

  const onFinish = values => {
    let updatedValues = Object.keys(values)
      .filter(key => !["city", "state"].includes(key))
      .reduce((obj, key) => {
        obj[key] = values[key]
        return obj
      }, {})

    updatedValues.location_text = values["city"] + ", " + values["state"]

    addFamily({ variables: updatedValues })
      .then(res =>
        setCreatedFamily(res?.data.insert_families.returning[0].family_id)
      )
      .then(_ => resizeFamily(createdFamily))
      .then(_ => setStatus({ type: "success", message: "Saved successfully!" }))
      .catch(err => setStatus({ type: "error", message: err }))
  }

  if (orgsLoading) {
    return <Loading />
  }

  return (
    <section className={styles.formContainer}>
      {editingFamilyId && (
        <Alert
          type="warning"
          message="Update doesn't work yet. Use Hasura."
          style={{ marginBottom: "3rem" }}
        />
      )}
      <Form layout={"vertical"} onFinish={onFinish}>
        <Form.Item
          label={"Organization:"}
          name={"organization_id"}
          rules={[
            {
              required: true,
              message: "Organization required.",
            },
          ]}
        >
          <Select
            showSearch
            size={"large"}
            filterOption={(input, option) =>
              option.children
                .join("")
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {organizations?.organizations.map(org => (
              <Select.Option
                key={org.organization_id}
                value={org.organization_id}
              >
                {org.full_name} ({org.location_text})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={"Display name:"}
          name={"display_name"}
          rules={[
            {
              required: true,
              message: "Display name required.",
            },
          ]}
        >
          <Input size={"large"} placeholder={"Neighbor X"} />
        </Form.Item>

        <Form.Item
          label={"Internal name:"}
          name={"internal_name"}
          rules={[
            {
              required: true,
              message: "Internal name required.",
            },
          ]}
        >
          <Input size={"large"} placeholder={"Neighbor X"} />
        </Form.Item>

        <Form.Item label={"Location:"}>
          <Form.Item
            label={"City"}
            name={"city"}
            rules={[
              {
                required: true,
                message: "City required.",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Input size={"large"} placeholder={"Greenwich"} />
          </Form.Item>
          <Form.Item
            label={"State"}
            name={"state"}
            rules={[
              {
                required: true,
                message: "State required.",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input size={"large"} placeholder={"CT"} />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={"Family size:"}
          name={"size"}
          rules={[
            {
              required: true,
              message: "Family size required.",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            size={"large"}
            min={1}
            placeholder={1}
          />
        </Form.Item>

        <Form.Item
          label={"Family members:"}
          name={"members"}
          rules={[
            {
              required: true,
              message: "Family constitution description required.",
            },
          ]}
        >
          <Input size={"large"} placeholder={"1 adult, 3 kids"} />
        </Form.Item>

        <Form.Item
          label={"Situation:"}
          name={"situation"}
          rules={[
            {
              required: true,
              message: "Situation description required.",
            },
          ]}
        >
          <Input.TextArea
            rows={6}
            size={"large"}
            placeholder={"This family's story..."}
          />
        </Form.Item>

        <Form.List name={"needs"}>
          {(fields, { add, remove }) => (
            <section>
              <section className={styles.needGrid}>
                <ul>
                  {fields.map(field => (
                    <li key={field.key}>
                      <article className={styles.needContainer}>
                        <Form.Item
                          label={"Need type:"}
                          style={{ width: "100%" }}
                          // name={"need_type"}
                          name={[field.name, "need_type"]}
                          fieldKey={[field.fieldKey, "need_type"]}
                          rules={[
                            {
                              required: true,
                              message: "Need type required.",
                            },
                          ]}
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.name !== curValues.name &&
                            prevValues.value !== curValues.value
                          }
                        >
                          <Select size={"large"}>
                            {Object.keys(DONATION_TYPES).map(type => (
                              <Select.Option key={type}>{type}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={"Need name:"}
                          rules={[
                            {
                              required: true,
                              message: "Need name required.",
                            },
                          ]}
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.name !== curValues.name &&
                            prevValues.value !== curValues.value
                          }
                          name={[field.name, "name"]}
                          fieldKey={[field.fieldKey, "name"]}
                        >
                          <Input size={"large"} />
                        </Form.Item>
                        <Form.Item
                          label={"Amount"}
                          rules={[
                            {
                              required: true,
                              message: "Need amount required.",
                            },
                          ]}
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.name !== curValues.name &&
                            prevValues.value !== curValues.value
                          }
                          name={[field.name, "value"]}
                          fieldKey={[field.fieldKey, "value"]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            size={"large"}
                            formatter={value =>
                              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={value => value.replace(/\$\s?|(,*)/g, "")}
                            min={1}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type={"dashed"}
                            block
                            icon={<MinusCircleOutlined />}
                            onClick={() => remove(field.name)}
                          >
                            Remove need
                          </Button>
                        </Form.Item>
                      </article>
                    </li>
                  ))}
                </ul>
              </section>

              <Form.Item style={{ marginTop: "1.5rem" }}>
                <Button
                  type={"dashed"}
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                >
                  Add need
                </Button>
              </Form.Item>
            </section>
          )}
        </Form.List>

        <Form.Item>
          <Checkbox
            checked={resizeOnSave}
            onClick={() => setResizeOnSave(!resizeOnSave)}
          >
            Resize need cards on save
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <ButtonCustom size="large" hierarchy="primary">
            Submit case
          </ButtonCustom>
        </Form.Item>
      </Form>
      {status.type && (
        <Alert
          style={{ marginTop: "3rem" }}
          message={status.message}
          description={
            status.type === "success" &&
            `Created family ${createdFamily}. Refresh this page to create another case.`
          }
          type={status.type}
        />
      )}
    </section>
  )
}

export default StoryBuilder

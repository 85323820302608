import { gql } from "@apollo/client"

export const GET_BULK_GIFT_CARD_PURCHASES = gql`
  query {
    gift_card_purchases(
      where: { type: { _eq: "bulk" }, is_completed: { _eq: true } }
    ) {
      purchase_id
      purchase_dte
      amount
      bulk_purchaser_name
    }
  }
`

export const GET_GIFT_CARD_MATCHING_PURCHASE_ID = gql`
  query {
    gift_cards(where: { gift_card_purchase_id: { _eq: $purchase_id } }) {
      amount
    }
  }
`

import React, { useState, useEffect } from "react"
import Papa from "papaparse"
import {
  DatePicker,
  Descriptions,
  Form,
  Input,
  Select,
  Table,
  TimePicker,
} from "antd"
import { useMutation, useQuery, gql } from "@apollo/client"
import styles from "../StoryBuilder/styles.module.scss"
import Loading from "../Loading"
import ButtonCustom from "../ButtonCustom"
import {
  GET_BULK_GIFT_CARD_PURCHASES,
  GET_GIFT_CARD_MATCHING_PURCHASE_ID,
} from "../../queries/getBulkGiftCardPurchases"
import { bulkCreateGiftCards } from "../../api"
import { formatMoney } from "../../utils/format"
import moment from "moment-timezone"

const INSERT_GIFT_CARDS = gql`
  mutation insertGiftCards($giftCards: [gift_cards_insert_input!]!) {
    insert_gift_cards(objects: $giftCards) {
      returning {
        gift_card_code
        sender_name
        recipient_name
        delivery_email
        amount
      }
    }
  }
`

const bulkCreate = async (giftCards, scheduledTimeUtc) => {
  if (!giftCards) {
    // TODO: expose and handle bad data error
    console.warn(`invalid gift create: ${giftCards.length}`)
  }
  const { result } = await bulkCreateGiftCards(giftCards, scheduledTimeUtc)
  return result
}

const GiftCardImporter = () => {
  const [file, setFile] = useState(null)
  const [giftCards, setGiftCards] = useState(null)
  const [stage, setStage] = useState(0)
  const [purchase, setPurchase] = useState(null)
  const [status, setStatus] = useState({ type: null, message: null })
  const [scheduledTimeEastern, setScheduledTimeEastern] = useState(null)
  const [scheduledTimeUTC, setScheduledTimeUTC] = useState(null)
  const [totalAmountToImport, setTotalAmountToImport] = useState(0)
  const [
    totalBalanceRemainingAfterImport,
    setTotalBalanceRemainingAfterImport,
  ] = useState(0)
  const [importing, setImporting] = useState(false)
  const { data: bulkPurchases, loading: purchasesLoading } = useQuery(
    GET_BULK_GIFT_CARD_PURCHASES
  )
  // const { loading, data, error } = useQuery(
  //   GET_GIFT_CARD_MATCHING_PURCHASE_ID, {
  //   variables: {
  //     purchase_id: purchase.purchase_id,
  //   },
  // })
  const [addGiftCards] = useMutation(INSERT_GIFT_CARDS)

  const scrollToTop = () => {
    window.scrollTo({ top: 0 })
  }

  const startOver = () => {
    setStage(0)
    setStatus({ type: null, message: null })
    scrollToTop()
  }

  const calculateBalances = (perc, cards) => {
    const totalAmount = cards.reduce((acc, card) => {
      return acc + Number(card.amount)
    }, 0)
    setTotalAmountToImport(totalAmount)
    setTotalBalanceRemainingAfterImport(perc.amount - totalAmount)
  }

  const onPreview = ({ purchaseId, deliveryDate, deliveryTime }) => {
    scrollToTop()
    const reader = new FileReader()
    reader.readAsText(file)

    reader.onload = event => {
      const csv = event.target.result
      const data = Papa.parse(csv, {
        skipEmptyLines: true,
      })

      data.data.shift()
      const formattedCSVData = data.data.map(row => {
        return {
          recipient_name: row[0],
          delivery_email: row[1],
          sender_name: row[2],
          amount: row[3],
          current_balance: row[3],
          is_active: true,
          gift_card_purchase_id: purchaseId,
        }
      })

      const date = moment(deliveryDate)
      const time = moment(deliveryTime)
      const deliveryMoment = moment(date).set({
        hour: time.hour(),
        minute: time.minute(),
        second: time.second(),
      })
      //switch to eastern timezone without converting the time
      const easternTime = moment(deliveryMoment).tz("America/New_York", true)
      //convert to UTC date converting the timezone
      const utcDate = moment(easternTime).utc()

      //convert to formats
      setScheduledTimeEastern(easternTime.format("MMMM DD, YYYY hh:mm A"))
      setScheduledTimeUTC(utcDate.format("YYYY-MM-DDTHH:mm:ss"))

      console.log(deliveryMoment.format("MMMM DD, YYYY HH:mm:ss zz"))
      console.log(
        "easternTime",
        easternTime.format("MMMM DD, YYYY HH:mm:ss zz")
      )
      console.log("utcTime", utcDate.format("MMMM DD, YYYY HH:mm:ss zz"))

      setGiftCards(formattedCSVData)

      const perc = bulkPurchases?.gift_card_purchases.find(
        purchase => purchase.purchase_id === purchaseId
      )
      setPurchase(perc)

      calculateBalances(perc, formattedCSVData)
      setStage(1)
    }
  }

  const onConfirm = () => {
    if (importing) return
    setImporting(true)

    console.log("scheduledDateTimeEastern = ", scheduledTimeEastern)
    console.log("scheduledDateTimeUtc = ", scheduledTimeUTC)

    addGiftCards({ variables: { giftCards } })
      .then(res => {
        const finalGiftCards = res?.data.insert_gift_cards.returning
        bulkCreate(finalGiftCards, scheduledTimeUTC).then(res => {
          setStatus({
            type: "success",
            message: `${finalGiftCards.length} gift cards successfully created!`,
          })
          setStage(2)
          scrollToTop()
          setImporting(false)
        })
      })
      .catch(err => setStatus({ type: "error", message: err }))
  }

  if (purchasesLoading) {
    return <Loading />
  }

  return (
    <section className={styles.formContainer}>
      <div>
        {stage === 0 && (
          <Form
            layout={"vertical"}
            name="csv-upload"
            method="POST"
            encType="multipart/form-data"
            onFinish={onPreview}
            initialValues={
              {
                // ["deliveryDate"]: momentTz().add(1, 'day'),
                // ["deliveryTime"]: momentTz().add(1, "day").hours(12).minutes(0),
              }
            }
          >
            <Form.Item
              label={"Bulk purchase:"}
              name={"purchaseId"}
              rules={[
                {
                  required: true,
                  message: "Select a bulk gift card purchase",
                },
              ]}
            >
              <Select
                showSearch
                size={"large"}
                filterOption={(input, option) =>
                  option.children
                    .join("")
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {bulkPurchases?.gift_card_purchases.map(purchase => (
                  <Select.Option
                    key={purchase.purchase_id}
                    value={purchase.purchase_id}
                  >
                    {purchase.bulk_purchaser_name}:{" "}
                    {formatMoney(purchase.amount)} on{" "}
                    {new Date(purchase.purchase_dte).toLocaleString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={"Delivery date:"}
              name={"deliveryDate"}
              rules={[
                {
                  required: true,
                  message: "Select a date to deliver the gift cards",
                },
              ]}
            >
              <DatePicker
                name="datePicker"
                style={{
                  width: "100%",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
                disabledDate={currentDate =>
                  currentDate && currentDate.isBefore(moment(), "day")
                }
                format={"MMMM DD, YYYY"}
              />
            </Form.Item>
            <Form.Item
              label={"Delivery time (Eastern Time):"}
              name={"deliveryTime"}
              rules={[
                {
                  required: true,
                  message: "Select a time to deliver the gift cards",
                },
              ]}
            >
              <TimePicker
                style={{
                  width: "100%",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
                placeholder="Select delivery time"
                s
                format="hh:mm A"
              />
            </Form.Item>
            <Form.Item
              label={"CSV file:"}
              name={"csvFile"}
              rules={[
                {
                  required: true,
                  message: "Select a CSV file to import",
                },
              ]}
            >
              <Input
                style={{ paddingTop: "0.8rem", paddingBottom: "0.8rem" }}
                type="file"
                name="file"
                accept=".csv"
                onChange={event => setFile(event.target.files[0])}
              />
            </Form.Item>
            <Form.Item>
              <ButtonCustom
                size="large"
                hierarchy="primary"
                style={{ marginTop: "3rem" }}
              >
                Preview
              </ButtonCustom>
            </Form.Item>
          </Form>
        )}
        {stage === 1 && (
          <Form layout={"vertical"} onFinish={onConfirm}>
            <Table
              style={{ marginBottom: "5rem" }}
              columns={[
                { title: "Purchase ID", dataIndex: "purchase_id" },
                {
                  title: "Bulk Purchaser Name",
                  dataIndex: "bulk_purchaser_name",
                },
                {
                  title: "Date Purchased",
                  dataIndex: "purchase_dte",
                  render: purchaseDate =>
                    new Date(purchaseDate).toLocaleString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }),
                },
                {
                  title: "Amount Paid",
                  dataIndex: "amount",
                  render: amount => `${formatMoney(amount)}`,
                },
              ]}
              dataSource={[purchase]}
              pagination={false}
              rowKey={record => record.purchase_id}
            />
            <Table
              style={{ marginBottom: "5rem" }}
              columns={[
                {
                  title: "Recipient Name",
                  dataIndex: "recipient_name",
                },
                {
                  title: "Delivery Email",
                  dataIndex: "delivery_email",
                },
                {
                  title: "Sender Name",
                  dataIndex: "sender_name",
                },
                {
                  title: "Amount",
                  dataIndex: "amount",
                  render: amount => `${formatMoney(amount)}`,
                },
                {
                  title: "Delivery Date",
                  render: () => scheduledTimeEastern + " Eastern Time",
                },
              ]}
              dataSource={giftCards}
              pagination={false}
              rowKey={record => record.recipient_name}
            />
            <Descriptions
              style={{ marginBottom: "5rem", fontWeight: "bold" }}
              column={1}
              bordered
              size="large"
            >
              <Descriptions.Item label="Total amount to import">
                {formatMoney(totalAmountToImport)}
              </Descriptions.Item>
              {/*<Descriptions.Item label="Total amount previously imported">$0</Descriptions.Item>*/}
              <Descriptions.Item label="Balance after this import">
                {formatMoney(totalBalanceRemainingAfterImport)}
              </Descriptions.Item>
            </Descriptions>
            <Form.Item>
              <ButtonCustom size="large" hierarchy="primary">
                Confirm import
                {/*{importing ? <Loading /> : "Confirm import"}*/}
              </ButtonCustom>
            </Form.Item>
            <span style={{ cursor: "pointer" }} onClick={() => setStage(0)}>
              Cancel
            </span>
          </Form>
        )}
        {stage === 2 && (
          <div>
            <p>{status.message}</p>
            <ButtonCustom
              size="large"
              hierarchy="primary"
              style={{ marginTop: "4rem" }}
              onClick={() => startOver()}
            >
              Start over
            </ButtonCustom>
          </div>
        )}
      </div>
    </section>
  )
}

export default GiftCardImporter

export const HOUSING = "housing"
export const OTHER = "unclassified"
export const CHILDCARE = "childcare"
export const GROCERIES = "groceries"
export const TRANSPORT = "transport"
export const BILLS = "bills"
export const EDUCATION = "education"
export const FURNISHINGS = "furnishings"
export const MEDICAL = "medical"
export const CLOTHING = "clothing"

export const DONATION_TYPES = {
  HOUSING,
  OTHER,
  CHILDCARE,
  GROCERIES,
  TRANSPORT,
  BILLS,
  EDUCATION,
  FURNISHINGS,
  MEDICAL,
  CLOTHING,
}

import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_ALL_FAMILIES } from "../../queries/allFamiles"
import { GET_ORGS_FOR_CAROUSEL } from "../../queries/getDataForCarousel"
import { Table, Button, Input, Dropdown, Menu } from "antd"
import { CheckOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons"
import Loading from "../Loading"
import { resizeFamily } from "../../api"

const FamilyTable = ({ setEditing }) => {
  const { data: families, loading: familiesLoading } = useQuery(
    GET_ALL_FAMILIES
  )
  const { data: organizations, loading: orgsLoading } = useQuery(
    GET_ORGS_FOR_CAROUSEL
  )
  const [searchResults, setSearchResults] = useState(null)

  if (familiesLoading || orgsLoading) {
    return <Loading />
  }

  const familiesFlat = families?.families.map(fam => ({
    ...fam,
    org_id: fam.organization.organization_id,
    org_name: fam.organization.name,
    is_open: fam.open_need_sum.aggregate.sum.value !== null,
    value_pending: fam.open_need_sum.aggregate.sum.value || 0,
  }))

  const orgFilters = organizations?.organizations
    .map(org => ({ text: org.full_name, value: org.organization_id }))
    .sort((a, b) => a.text.localeCompare(b.text))

  const search = term => {
    const filtered = familiesFlat
      .filter(rec =>
        Object.keys(rec).some(k =>
          String(rec[k])
            .toLowerCase()
            .includes(term.toLowerCase())
        )
      )
      .map(rec => rec.family_id)
    setSearchResults(filtered)
  }

  return (
    <section>
      <Input.Search
        enterButton
        placeholder={"Find a case"}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
        onSearch={search}
      />
      <Table
        dataSource={
          searchResults
            ? familiesFlat.filter(fam => searchResults.includes(fam.family_id))
            : familiesFlat
        }
        columns={[
          {
            title: "Organization",
            dataIndex: "org_name",
            key: "org_name",
            filterMultiple: true,
            filters: orgFilters,
            onFilter: (value, record) => value === record.org_id,
            sorter: (a, b) => a.org_name.localeCompare(b.org_name),
          },
          {
            title: "Name",
            dataIndex: "display_name",
            key: "display_name",
            sorter: (a, b) => a.org_name.localeCompare(b.org_name),
          },
          {
            title: "Location",
            dataIndex: "location_text",
            key: "location_text",
            sorter: (a, b) => a.location_text.localeCompare(b.location_text),
          },
          {
            title: "Case open",
            dataIndex: "is_open",
            key: "is_open",
            render: text => (
              <span>
                {text ? (
                  <CheckOutlined style={{ color: "#66cc70" }} />
                ) : (
                  <CloseOutlined style={{ color: "#cc6666" }} />
                )}
              </span>
            ),
            filters: [
              {
                text: "Open",
                value: true,
              },
              {
                text: "Closed",
                value: false,
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => value === record.is_open,
          },
          {
            title: "Value pending",
            dataIndex: "value_pending",
            key: "value_pending",
            render: text => `$${text}`,
            sorter: (a, b) => b.value_pending - a.value_pending,
          },
          {
            title: "",
            dataIndex: "family_id",
            key: "edit",
            render: text => (
              <span>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => resizeFamily(text)}>
                        Resize
                      </Menu.Item>
                      <Menu.Item onClick={() => setEditing(text)}>
                        Edit
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button>
                    Actions <DownOutlined />
                  </Button>
                </Dropdown>
              </span>
            ),
          },
        ]}
      />
    </section>
  )
}

export default FamilyTable

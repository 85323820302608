import { gql } from "@apollo/client"

export const GET_ORGS_FOR_CAROUSEL = gql`
  query {
    organizations(
      where: {
        name: {}
        full_name: { _is_null: false }
        location_text: { _is_null: false }
      }
    ) {
      organization_id
      logo_url
      full_name
      description
      location_text
      region {
        location_coords
      }
      hidden
    }
  }
`

export const GET_CORPS_FOR_CAROUSEL = gql`
  query {
    corporates {
      id
      logo_url
      name
      link_name
      filter_criteria_needs_type
      filter_criteria_geo
    }
  }
`
